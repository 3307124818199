<template>

  <div class="purchase__request">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Purchase Requests
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            v-if="canViewThisAction('create', 'PurchaseRequest') || canViewThisAction('my-request-create', 'MyPurchaseRequest')"
            variant="success"
            class="mobile_create_button"
            :to="{ name: 'purchasing-purchase-requests-create' }"
            style="padding: 0.786rem 0.7rem;"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Purchase Request</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
          <b-button
            v-else
            v-b-tooltip.hover.bottom="'You don’t have permission to create purchase request.'"
            variant="success"
            style="opacity: 0.65; padding: 0.786rem 0.7rem;"
            aria-readonly=""
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Purchase Request</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content pr-tabs"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'in-request'"
            lazy
            @click="changeRoute('in-request')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Purchase Request <span class="rounded-number">{{ inRequestCount }}</span></span>
            </template>
            <in-purchase-request
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :assignee-options="assigneeOptions"
              :requestor-options="requestorOptions"
              @emitCountPurchaseRequests="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'in-quotation'"
            lazy
            @click="changeRoute('in-quotation')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Quotation <span class="rounded-number">{{ inQuotationCount }}</span></span>
            </template>
            <in-quotation
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :assignee-options="assigneeOptions"
              :requestor-options="requestorOptions"
              @emitCountPurchaseRequests="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'in-order'"
            lazy
            @click="changeRoute('in-order')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Purchase Order <span class="rounded-number">{{ inOrderCount }}</span></span>
            </template>
            <in-order
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :assignee-options="assigneeOptions"
              :requestor-options="requestorOptions"
              @emitCountPurchaseRequests="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'in-delivery'"
            lazy
            @click="changeRoute('in-delivery')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Delivery <span class="rounded-number">{{ inDeliveryCount }}</span></span>
            </template>
            <in-delivery
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :assignee-options="assigneeOptions"
              :requestor-options="requestorOptions"
              @emitCountPurchaseRequests="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'all-requests'"
            lazy
            @click="changeRoute('all-requests')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Requests <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-requests
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :assignee-options="assigneeOptions"
              :requestor-options="requestorOptions"
              @emitCountPurchaseRequests="countPurchaseRequests"
            />
          </b-tab>
          <b-tab
            :active="$route.params.type == 'in-my-pending-sign'"
            lazy
            @click="changeRoute('in-my-pending-sign')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Awaiting My Signature <span class="rounded-number">{{ mySignCount }}</span></span>
            </template>
            <in-my-sign
              class="mt-2 pt-75"
              :business-entity-options="businessEntityOptions"
              :vendor-options="vendorOptions"
              :assignee-options="assigneeOptions"
              :requestor-options="requestorOptions"
              @emitCountPurchaseRequests="countPurchaseRequests"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InDelivery from './delivery/InDelivery.vue'
import InOrder from './order/InOrder.vue'
import InPurchaseRequest from './purchase-request/InPurchaseRequest.vue'
import InQuotation from './quotation/InQuotation.vue'
import AllRequests from './all-requests/AllRequests.vue'
import InMySign from './my-sign-requests/InMySign.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    InPurchaseRequest,
    InDelivery,
    InOrder,
    InQuotation,
    AllRequests,
    InMySign,
  },
  directives: { 'b-tooltip': VBTooltip },
  data() {
    return {
      canViewThisAction,
      allCount: 0,
      mySignCount: 0,
      inRequestCount: 0,
      inQuotationCount: 0,
      inOrderCount: 0,
      inDeliveryCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Purchase Request',
          param: 'in-request',
          count: 0,
        },
        {
          title: 'Quotation',
          param: 'in-quotation',
          count: 0,
        },
        {
          title: 'Purchase Order',
          param: 'in-order',
          count: 0,
        },
        {
          title: 'Delivery',
          param: 'in-delivery',
          count: 0,
        },
        {
          title: 'All Requests',
          param: 'all-requests',
          count: 0,
        },
        {
          title: 'Awaiting My Signature',
          param: 'in-my-pending-sign',
          count: 0,
        },
      ],
      businessEntityOptions: [],
      vendorOptions: [],
      assigneeOptions: [],
      requestorOptions: [],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countPurchaseRequests()
    this.$http.get('purchase/purchase-request/index/extra-options')
      .then(response => {
        this.businessEntityOptions = response.data.businessEntityOptions ?? []
        this.vendorOptions = response.data.vendorOptions ?? []
        this.assigneeOptions = response.data.cpUserOptions ?? []
        this.requestorOptions = response.data.requestorOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countPurchaseRequests() {
      this.$http.get('purchase/purchase-request/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.inRequestCount = response.data.inRequestCount
          this.inQuotationCount = response.data.inQuotationCount
          this.inOrderCount = response.data.inOrderCount
          this.inDeliveryCount = response.data.inDeliveryCount
          this.mySignCount = response.data.mySignCount
          this.pageTabs.forEach(element => {
            if (element.title === 'Purchase Request') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.inRequestCount
            } else if (element.title === 'Quotation') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.inQuotationCount
            } else if (element.title === 'Purchase Order') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.inOrderCount
            } else if (element.title === 'Delivery') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.inDeliveryCount
            } else if (element.title === 'All Requests') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Awaiting My Signature') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.mySignCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
